import Alpine from 'alpinejs'

window.Alpine = Alpine

Alpine.start()

const menuItems = Array.from(document.querySelectorAll(`nav a`));
const sections = menuItems
    .map(menuItem => {
        const id = menuItem.getAttribute('href').replace('#', '');

        return {
            id,
            menuItem,
            section: document.querySelector(`#${id}`)
        };
    });

const updateMenu = () => {
    let currentMenuItem;
    for (const section of sections) {
        if (window.scrollY + 150 > section.section.offsetTop) {
            currentMenuItem = section.menuItem;
        }
    }

    if (currentMenuItem) {
        menuItems.forEach(item => item.classList.remove('text-bg-primary'));
        currentMenuItem.classList.add('text-bg-primary');
    }
}

addEventListener('scroll', updateMenu, {passive: true, capture: false});
updateMenu();
